import * as React from "react";
import styled from "styled-components";
import styledTS from "styled-components-ts";
import TextareaAutosize from "react-autosize-textarea";
import axios from "axios";

interface IProps {
  formType: string;
  workType?: string;
}

interface IState {
  isSubmitting: boolean;
  submitted: boolean;
  messageShown: boolean;
  formData: IFormData;
}

interface IFormData {
  name: string;
  company: string;
  email: string;
  message: string;
}

export default class ContactForm extends React.Component<IProps, IState> {
  state = {
    isSubmitting: false,
    submitted: false,
    messageShown: false,
    formData: {
      name: "",
      company: "",
      email: "",
      message: ""
    }
  };

  render() {
    const { formType } = this.props;
    const messageShown = formType === "normal" || this.state.messageShown;

    let title = "Contact via email";
    let submittedMessage =
      "Message submitted\u2014thank you. I look forward to taking soon.";
    let notSubmittedMessage = "Response within 1 business day.";
    let showAllFields = true;

    if (formType === "call") {
      title = "Email to schedule a call";
    } else if (formType === "whitepaper") {
      title = "Download the free whitepaper";
      submittedMessage =
        "Thank you. Your whitepaper will be delivered shortly.";
      notSubmittedMessage = "";
      showAllFields = false;
    }

    return (
      <ContactFormContainer>
        {showAllFields && (
          <>
            <ContactFormType restrictHeight={messageShown}>
              <ContactFormButton href="https://calendly.com/markbao/consulting-intro-meeting/07-31-2018">
                <ContactFormTypeTitle>
                  View my appointment calendar
                </ContactFormTypeTitle>
                <ContactFormTypeSubtitle>
                  Schedule a call directly.
                </ContactFormTypeSubtitle>
              </ContactFormButton>
            </ContactFormType>
            <ContactFormType restrictHeight={messageShown}>
              <ContactFormButton href="mailto:?subject=Please%20schedule%20a%20call%20with%20Mark%20Bao&body=https%3A%2F%2Fcalendly.com%2Fmarkbao%2Fconsulting-intro-meeting">
                <ContactFormTypeTitle>
                  Send calendar link to your scheduler
                </ContactFormTypeTitle>
                <ContactFormTypeSubtitle>
                  Forward calendar link via email.
                </ContactFormTypeSubtitle>
              </ContactFormButton>
            </ContactFormType>
          </>
        )}
        <ContactFormTypeWide fullWidth={this.props.formType === "whitepaper"}>
          <ContactFormEmailContainer>
            <ContactFormTypeTitle>{title}</ContactFormTypeTitle>
            {notSubmittedMessage &&
              !this.state.submitted && (
                <ContactFormTypeSubtitle>
                  {notSubmittedMessage}
                </ContactFormTypeSubtitle>
              )}
            {this.state.submitted && (
              <ContactFormTypeSubmitted>
                {submittedMessage}
              </ContactFormTypeSubmitted>
            )}
            {!this.state.submitted && (
              <form onSubmit={this.handleSubmit}>
                <ContactFormEmailForm>
                  <div className="w-1/2 px-2">
                    <ContactFormEmailInput
                      placeholder="Name"
                      name="name"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <ContactFormEmailInput
                      placeholder="Company Name"
                      name="company"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="w-full px-2 mt-2">
                    <ContactFormEmailInput
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  {this.props.formType !== "whitepaper" && (
                    <>
                      {!messageShown ? (
                        <div className="w-full px-2 mt-2">
                          <button
                            className="text-xs text-aqua opacity-75 hover:opacity-100"
                            onClick={this.handleShowMessage}
                            type="button"
                          >
                            Add an optional message
                          </button>
                        </div>
                      ) : (
                        <div className="w-full px-2 mt-2">
                          <ContactFormEmailTextarea
                            placeholder="Message"
                            name="message"
                            onChange={this.handleChange}
                            rows={3}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {this.props.formType === "whitepaper" && (
                    <ContactFormInfo>
                      By submitting this form, you agree to receive
                      communication from Mark Bao Consulting.
                    </ContactFormInfo>
                  )}
                </ContactFormEmailForm>
                <div className="-mx-4 mt-4">
                  <ContactFormEmailSubmit
                    type="submit"
                    disabled={this.state.isSubmitting}
                  >
                    {this.state.isSubmitting ? "Sending..." : "Send"}
                  </ContactFormEmailSubmit>
                </div>
              </form>
            )}
          </ContactFormEmailContainer>
        </ContactFormTypeWide>
      </ContactFormContainer>
    );
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({
      isSubmitting: true
    });

    const data = {
      name: this.state.formData.name,
      company: this.state.formData.company,
      email: this.state.formData.email,
      message: this.state.formData.message,
      type: this.props.formType,
      workType: ""
    };

    if (this.props.workType) {
      data.workType = this.props.workType;
    }

    const res = await axios.post(
      "https://usebasin.com/f/c1e597ff3ae0.json",
      data
    );

    this.setState({
      isSubmitting: false,
      submitted: true
    });

    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: "contactFormSubmitted"
      });
    }
  };

  handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.FormEvent<HTMLTextAreaElement>
  ) => {
    const name = (e.target as HTMLInputElement | HTMLTextAreaElement).name;
    const value = (e.target as HTMLInputElement | HTMLTextAreaElement).value;

    if (["name", "email", "company", "message"].includes(name)) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
      });
    }
  };

  handleShowMessage = () => {
    this.setState({
      messageShown: true
    });
  };
}

const ContactFormContainer = styled.div.attrs({
  className: "-mx-2 flex flex-wrap"
})``;

interface IContactFormTypeProps {
  restrictHeight?: boolean;
}

const ContactFormType = styledTS<IContactFormTypeProps>(styled.div).attrs({
  className: "w-full mb-4 lg:mb-0 lg:w-1/4 px-2"
})`

  @media (min-width: 1040px) {
    ${props => props.restrictHeight && "height: 12rem;"}
  }
`;

interface IContactFormTypeWideProps {
  fullWidth?: boolean;
}

const ContactFormTypeWide = styledTS<IContactFormTypeWideProps>(
  styled.div
).attrs({
  className: (props: IContactFormTypeWideProps) =>
    `w-full mb-4 lg:mb-0 ${!props.fullWidth && "lg:w-1/2"} px-2`
})``;

const ContactFormButton = styled.a.attrs({
  className: "border border-aqua-light-50 hover:border-aqua p-4 block h-full"
})`
  transition: transform 0.1s ease-out, border 0.1s ease-out;

  &:hover {
    transform: scale(1.05, 1.05);
  }
`;

const ContactFormTypeTitle = styled.div.attrs({
  className: "text-aqua font-bold mb-4"
})``;

const ContactFormTypeSubtitle = styled.div.attrs({
  className: "text-grey-darker text-sm"
})``;

const ContactFormTypeSubmitted = styled.div.attrs({
  className: "text-grey-darker text-sm mb-4"
})``;

const ContactFormEmailContainer = styled.a.attrs({
  className: "border border-aqua-light-50 p-4 pb-0 block h-full"
})``;

const ContactFormEmailForm = styled.div.attrs({
  className: "flex flex-wrap -mx-2 mt-4"
})``;

const ContactFormEmailInput = styled.input.attrs({
  className:
    "block border-b border-aqua-light-50 focus:border-aqua py-2 w-full text-aqua"
})`
  &::placeholder {
    color: #80caca;
    opacity: 1;
  }

  &:active,
  &:focus {
    outline: 0;
  }
`;

const ContactFormEmailTextarea = styled(TextareaAutosize).attrs({
  className:
    "block border-b border-aqua-light-50 focus:border-aqua py-2 w-full text-aqua"
})`
  &::placeholder {
    color: #80caca;
    opacity: 1;
  }

  &:active,
  &:focus {
    outline: 0;
  }
`;

const ContactFormEmailSubmit = styled.button.attrs({
  className:
    "block w-full bg-aqua-light-90 hover:bg-aqua-light-80 active:bg-aqua-light-75 text-aqua text-left py-3 pl-4"
})`
  transition: background-color 0.2s ease-out;
`;

const ContactFormInfo = styled.div.attrs({
  className: "text-xs w-full px-2 mt-4 text-grey-darker"
})``;
