import * as React from "react";
import styled from "styled-components";
import Project from "./project";
import { projectData } from "./projectData";
import _ from "lodash";

interface IProps {
  selectedProjects?: string[];
}

export interface ProjectType {
  key: string;
  title: string;
  subtitle: string;
  images: string[];
  bgColor: string;
  textColor: string;
  imageShadowColor: string;
  content: React.ReactNode;
  technologies: string[];
}

export default class Projects extends React.Component<IProps, {}> {
  render() {
    const { selectedProjects } = this.props;

    let projects = projectData;
    if (selectedProjects && selectedProjects.length > 0) {
      projects = _.pick(projects, selectedProjects);
    }

    return (
      <ProjectsContainer>
        {Object.values(projects).map(project => (
          <Project project={project} key={project.key} />
        ))}
      </ProjectsContainer>
    );
  }
}

const ProjectsContainer = styled.div``;
