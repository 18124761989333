import * as React from "react";
import styled from "styled-components";
import styledTS from "styled-components-ts";
import Swipeable from "react-swipeable";
import { ProjectType } from "./projects";
import { HotKeys } from "react-hotkeys";

interface IProps {
  project: ProjectType;
}

interface IState {
  currentImage: number;
}

export default class Project extends React.Component<IProps, IState> {
  state = {
    currentImage: 1
  };

  private projectImagesInnerRef: React.RefObject<
    HTMLDivElement
  > = React.createRef();

  render() {
    const { project } = this.props;
    const map = { left: "left", right: "right" };
    const handlers = { left: this.handleKeyLeft, right: this.handleKeyRight };

    return (
      <ProjectContainer bgColor={project.bgColor} textColor={project.textColor}>
        <div className="container px-6 mx-auto py-12">
          <HotKeys
            keyMap={map}
            handlers={handlers}
            focused={true}
            attach={typeof window !== "undefined" ? window : undefined}
          >
            <ProjectHeader>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectSubtitle>{project.subtitle}</ProjectSubtitle>
            </ProjectHeader>
            <ProjectImages
              onSwipingLeft={this.handleSwipingLeft}
              onSwipingRight={this.handleSwipingRight}
              onSwiped={this.handleSwiped}
            >
              <ProjectImagesInner
                currentImage={this.state.currentImage}
                imageShadowColor={project.imageShadowColor}
              >
                <ProjectImagesMovingContainer
                  innerRef={this.projectImagesInnerRef}
                >
                  {project.images.map((image, idx) => (
                    <ProjectImage
                      key={idx}
                      src={image}
                      onClick={() => this.handleClickImage(idx + 1)}
                      current={this.state.currentImage === idx + 1}
                    />
                  ))}
                </ProjectImagesMovingContainer>
              </ProjectImagesInner>
            </ProjectImages>
            <ProjectImagesPaginatorContainer>
              <ProjectImagesPaginator dotColor={project.textColor}>
                {project.images.map((image, idx) => (
                  <ProjectImagesPaginatorItem
                    current={this.state.currentImage === idx + 1}
                    onClick={() => this.handleClickPaginatorItem(idx + 1)}
                    key={idx}
                  />
                ))}
              </ProjectImagesPaginator>
            </ProjectImagesPaginatorContainer>
            <ProjectDescription>
              <ProjectContent>
                <ProjectDescriptionHeader>Work</ProjectDescriptionHeader>
                {project.content}
              </ProjectContent>
              <ProjectSidebar>
                <ProjectDescriptionHeader>
                  Technologies
                </ProjectDescriptionHeader>
                <ul>
                  {project.technologies.map(technology => (
                    <li key={technology}>{technology}</li>
                  ))}
                </ul>
              </ProjectSidebar>
            </ProjectDescription>
          </HotKeys>
        </div>
      </ProjectContainer>
    );
  }

  handleClickImage = (idx: number) => {
    let nextImage = idx;

    if (this.state.currentImage === idx) {
      // Jump to next image if not last
      if (this.state.currentImage === this.props.project.images.length) {
        // Last image: jump to first
        nextImage = 1;
      } else {
        nextImage = idx + 1;
      }
    }

    this.changeCurrentImage(nextImage);
  };

  handleClickPaginatorItem = (idx: number) => {
    this.changeCurrentImage(idx);
  };

  changeCurrentImage = (newIdx: number) => {
    this.setState({
      currentImage: newIdx
    });
  };

  handleSwipingLeft = (e: any, absX: number) => {
    this.handleSwiping(absX * -1);
  };

  handleSwipingRight = (e: any, absX: number) => {
    this.handleSwiping(absX);
  };

  handleSwiping = (deltaX: number) => {
    if (this.projectImagesInnerRef && this.projectImagesInnerRef.current) {
      this.projectImagesInnerRef.current.style.transform = `translate3d(${deltaX}px, 0, 0)`;
      this.projectImagesInnerRef.current.style.transition = "none";
    }
  };

  handleSwipedLeft = (deltaX: number) => {
    this.resetSwipingState();
    this.goToNextImage();
  };

  handleSwipedRight = (deltaX: number) => {
    this.resetSwipingState();
    this.goToPreviousImage();
  };

  goToNextImage = () => {
    // Go to next image if not last
    if (this.state.currentImage < this.props.project.images.length) {
      this.changeCurrentImage(this.state.currentImage + 1);
    }
  };

  goToPreviousImage = () => {
    // Go to previous image if not first
    if (this.state.currentImage > 1) {
      this.changeCurrentImage(this.state.currentImage - 1);
    }
  };

  resetSwipingState = () => {
    if (this.projectImagesInnerRef && this.projectImagesInnerRef.current) {
      this.projectImagesInnerRef.current.style.transform = `translate3d(0, 0, 0)`;
      this.projectImagesInnerRef.current.style.transition = null;
    }
  };

  handleSwiped = (e: any, deltaX: number, deltaY: number) => {
    if (deltaX < 0) {
      this.handleSwipedRight(deltaX * -1);
    } else if (deltaX > 0) {
      this.handleSwipedLeft(deltaX);
    }
  };

  carouselIsInView = (): boolean => {
    if (this.projectImagesInnerRef && this.projectImagesInnerRef.current) {
      const top =
        this.projectImagesInnerRef.current.getBoundingClientRect().top + 100;
      const vh = window.innerHeight || document.documentElement.clientHeight;
      if (top > 0 && top < vh) {
        // Visible
        return true;
      }
    }
    return false;
  };

  handleKeyLeft = () => {
    if (this.carouselIsInView()) {
      this.goToPreviousImage();
    }
  };

  handleKeyRight = () => {
    if (this.carouselIsInView()) {
      this.goToNextImage();
    }
  };
}

interface IProjectsContainerProps {
  bgColor: string;
  textColor: string;
}

const ProjectContainer = styledTS<IProjectsContainerProps>(styled.div)`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor}
  overflow: hidden;
`;

const ProjectHeader = styled.div.attrs({
  className: "mb-12"
})`
  font-size: 36px;
  letter-spacing: -1px;
  line-height: normal;

  @media (max-width: 623px) {
    font-size: 30px;
  }
`;

const ProjectTitle = styled.h2.attrs({
  className: "font-bold"
})`
  font-size: 36px;

  @media (max-width: 623px) {
    font-size: 30px;
  }
`;

const ProjectSubtitle = styled.h3.attrs({
  className: "font-normal"
})`
  font-size: 36px;
  opacity: 0.5;

  @media (max-width: 623px) {
    font-size: 30px;
  }
`;

const ProjectImages = styled(Swipeable)``;

interface IProjectImagesInnerProps {
  currentImage: number;
  imageShadowColor: string;
}

const ProjectImagesInner = styledTS<IProjectImagesInnerProps>(styled.div)`
  width: 10000px;
  transition: transform 0.25s ease-out;
  font-size: 0;
  position: relative;
  transform: translate3d(calc(-${props =>
    props.currentImage - 1} * (100vw - 3rem + 28px)), 0, 0);
  
  @media (min-width: 624px) {
    transform: translateX(${props => (props.currentImage - 1) * -556 + "px"});
  }

  @media (min-width: 816px) {
    transform: translateX(${props => (props.currentImage - 1) * -748 + "px"});
  }

  @media (min-width: 1040px) {
    transform: translateX(${props => (props.currentImage - 1) * -972 + "px"});
  }

  img {
    box-shadow: 0 10px 30px ${props => props.imageShadowColor};
    border-radius: 3px;
    margin-right: 28px;
    cursor: pointer;
    width: calc(100vw - 3rem);
    transition: opacity 0.1s ease-out;
    
    &:hover {
      opacity: 1;
    }
    
    @media (min-width: 624px) {
      width: 528px;
    }

    @media (min-width: 816px) {
      width: 720px;
    }

    @media (min-width: 1040px) {
      width: 944px;
    }
  }
`;

const ProjectImagesMovingContainer = styled.div`
  transition: transform 0.25s ease-out;
`;

interface IProjectImageProps {
  current?: boolean;
}

const ProjectImage = styledTS<IProjectImageProps>(styled.img)`
  opacity: ${props => (props.current ? 1 : 0.75)};
`;

const ProjectImagesPaginatorContainer = styled.div``;

interface IProjectImagesPaginatorProps {
  dotColor: string;
}

const ProjectImagesPaginator = styledTS<IProjectImagesPaginatorProps>(
  styled.ul
)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;

  li:after {
    background-color: ${props => props.dotColor};
  }
`;

interface IProjectImagesPaginatorItemProps {
  current?: boolean;
}

const ProjectImagesPaginatorItem = styledTS<IProjectImagesPaginatorItemProps>(
  styled.li
)`

padding: 10px 5px;

&:hover {
  cursor: pointer;
}

&:hover:after {
  opacity: 1;
}

&:after {
  content: "";
  display: block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  opacity: ${props => (props.current ? 1 : 0.3)};
}
`;

const ProjectDescription = styled.div.attrs({
  className: "flex flex-wrap text-base sm:text-xl mt-6"
})``;

const ProjectContent = styled.div.attrs({
  className: "md:pr-6 w-full md:1-3/4"
})`
  flex: 2;
  line-height: 30px;

  @media (max-width: 623px) {
    line-height: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
    @media (max-width: 623px) {
      margin-bottom: 0.75rem;
    }
  }

  span {
    font-size: 0.75em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 0.75rem;
  }
`;

const ProjectSidebar = styled.div.attrs({
  className: "md:pl-6 w-full md:w-1/4 text-base"
})`
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 0.25rem;
  }
`;

const ProjectDescriptionHeader = styled.div.attrs({
  className: "text-base font-bold"
})`
  line-height: 30px;
`;
