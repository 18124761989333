import * as React from "react";
import styled from "styled-components";

import cellar1 from "../images/cellar-1.png";
import atom1 from "../images/atom-1.png";

export default class Hero extends React.Component<{}, {}> {
  render() {
    return (
      <HeroContainerFullWidth>
        <HeroContainer>
          <HeroText>
            Design and development of custom software to solve business
            problems, improve processes, and introduce new products to market.
          </HeroText>
          <HeroContainerMobileImages>
            <HeroContainerMobileTopImage src={cellar1} />
            <HeroContainerMobileBottomImage src={atom1} />
          </HeroContainerMobileImages>
          {/*<LeftImage src={cellar1} />*/}
          {/*<RightImage src={atom1} />*/}
        </HeroContainer>
        <HeroContainerImagesContainer>
          <HeroContainerImagesOuter>
            <HeroContainerImagesInner>
              <HeroContainerLeftImage />
            </HeroContainerImagesInner>
          </HeroContainerImagesOuter>
        </HeroContainerImagesContainer>
        {/*<HeroContainerRightImage />*/}
      </HeroContainerFullWidth>
    );
  }
}

const HeroContainerFullWidthX = styled.div``;

const HeroContainerFullWidth = styled.div`
  width: 100%;
  //overflow-x: hidden;
  position: relative;
  padding-bottom: 0;

  @media (min-width: 1040px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const HeroContainer = styled.div`
  background-color: #f1eefd;
  padding: 1.5rem;
  margin: auto;
  position: relative;
  width: 100%;
  height: auto;

  @media (min-width: 624px) {
    padding: 50px;
    height: 640px;
  }

  // XL
  @media (min-width: 1248px) {
    width: 1248px;
    position: initial;
  }

  // MacBook Pro
  @media (min-width: 1440px) {
    position: relative;
  }
`;

const HeroText = styled.div.attrs({
  className: "text-brand"
})`
  font-size: 25px;
  letter-spacing: -0.03em;
  line-height: 1.3em;
  max-width: 500px;

  // MacBook Pro
  @media (max-width: 623px) {
    font-size: 20px;
  }
`;

const LeftImage = styled.img`
  position: absolute;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  // Large
  //@media (min-width: 624px) {
  // Relative to container
  top: 190px;
  left: 0;
  width: 75%;
  //}

  @media (min-width: 624px) {
    // Relative to container
    width: 55%;
  }

  // XL
  @media (min-width: 1248px) {
    // Absolute
    top: 400px;
    left: 0;
    width: 780px;
  }

  // MacBook Pro
  @media (min-width: 1440px) {
    // Relative to container
    top: 190px;
    left: -100px;
  }
`;

const RightImage = styled.img`
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 75%;

  // Relative to container
  top: 250px;
  right: 0;
  left: auto;

  // Small
  @media (min-width: 624px) {
    // Relative to container
    width: 55%;
  }

  // XL
  @media (min-width: 1248px) {
    // Absolute
    top: 185px;
    left: auto;
    right: -200px;
    width: 860px;
  }

  // MacBook Pro
  @media (min-width: 1440px) {
    top: -25px;
    left: 600px;
    right: auto;
  }
`;

const HeroContainerImagesContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;

  @media (min-width: 624px) {
    display: block;
  }
`;

const HeroContainerImagesOuter = styled.div`
  @media (min-width: 624px) {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1248px) {
    width: 1248px;
    height: 100%;
    margin: auto;
  }
`;

const HeroContainerImagesInner = styled.div`
  @media (min-width: 624px) {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1248px) {
    position: relative;
    width: 1648px;
    height: 100%;
    margin-left: -200px;
  }
`;

const HeroContainerLeftImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
  background-image: url(${atom1}), url(${cellar1});
  background-repeat: no-repeat;

  background-size: 100% auto, 100% auto;
  background-position: 0 300px, 0 150px;

  @media (min-width: 624px) {
    background-size: 55% auto, 55% auto;
    background-position: 45vw 250px, 0 200px;
    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
  }

  @media (min-width: 1040px) {
    background-size: 650px auto, 650px auto;
    background-position: 580px 25px, 0 250px;
  }

  @media (min-width: 1248px) {
    background-size: 780px auto, 780px auto;
    background-position: 800px 0, 175px 235px;
  }

  @media (min-width: 1440px) {
    background-position: 800px 0, 100px 235px;
  }
`;

const HeroContainerRightImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const HeroContainerMobileImages = styled.div`
  margin-top: 1.5rem;
  position: relative;
  height: 0;
  padding-bottom: 90%;
  //margin-left: -1.5rem;
  //margin-right: -1.5rem;

  @media (min-width: 624px) {
    display: none;
  }
`;

const HeroContainerMobileTopImage = styled.img`
  position: absolute;
`;

const HeroContainerMobileBottomImage = styled.img`
  position: absolute;
  bottom: 0;
`;
