import * as React from "react";
import { ProjectType } from "./projects";

import cellar1 from "../images/cellar-1.png";
import cellar2 from "../images/cellar-2.png";
import cellar3 from "../images/cellar-3.png";
import cellar4 from "../images/cellar-4.png";

import atom1 from "../images/atom-1.png";
import atom2 from "../images/atom-2.png";
import atom3 from "../images/atom-3.png";
import atom4 from "../images/atom-4.png";
import atom5 from "../images/atom-5.png";

import arxivlab1 from "../images/arxivlab-1.png";
import arxivlab2 from "../images/arxivlab-2.png";
import arxivlab3 from "../images/arxivlab-3.png";
import arxivlab4 from "../images/arxivlab-4.png";

import kindred1 from "../images/kindred-1.png";
import kindred2 from "../images/kindred-2.png";
import kindred3 from "../images/kindred-3.png";
import kindred4 from "../images/kindred-4.png";

export const projectData: { [id: string]: ProjectType } = {
  cellar: {
    key: "cellar",
    title: "Cellar",
    subtitle: "Wine inventory management system",
    images: [cellar1, cellar2, cellar3, cellar4],
    bgColor: "#eadbe2",
    textColor: "#6f0c3c",
    imageShadowColor: "rgba(111, 12, 60, 0.15)",
    content: (
      <>
        <p>Design, frontend/backend development, process strategy</p>
        <p>
          Cellar is an inventory management system, custom-built for a
          commercial wineseller. Cellar manages inventory across multiple
          locations using a barcode label system, and includes an iPhone app
          that scans barcodes to view and check out items.
        </p>
        <p>
          I worked with the client to design the barcode label according to
          their specifications, and meet their inventory control workflow by
          including features like Inventory Check.
        </p>
        <p>
          Designed and developed from scratch, working with the client to design
          a process for labeling inventory items and a check-in/check-out flow.
        </p>
      </>
    ),
    technologies: ["Ruby on Rails", "jQuery", "PostgreSQL", "Objective-C"]
  },
  atom: {
    key: "atom",
    title: "Atom",
    subtitle: "Financial data and news platform",
    images: [atom1, atom2, atom3, atom4, atom5],
    bgColor: "#354050",
    textColor: "#d3dbe5",
    imageShadowColor: "rgba(0, 0, 0, 0.15)",
    content: (
      <>
        <p>
          Design and development of full product, data ingestion and management,
          real-time chat
        </p>
        <p>
          Atom is a source of financial data and news for investors. It provides
          real-time equity price data, a place to track stocks you’re interested
          in, and detailed historical financial data. To help investors find
          promising equities, Atom provides a screener for finding new stocks
          based on 20 financial indicators. Finally, it allows groups of
          investors like investing clubs to collaborate with a group chat
          system.
        </p>
        <p>
          I designed the product to feel forward-thinking and innovative,
          differentiating it from current financial data tools, using a periodic
          table-like ‘stock tile’ format to represent companies, which gives the
          user a quick way to view the performance of a stock.
        </p>
        <ul>
          <li>
            Designed and developed a responsive, single-page application (SPA)
            frontend with real-time stock price updates, using React and GraphQL
          </li>
          <li>
            Developed a backend and API with user accounts, and stock tracking,
            with Node.js, TypeScript, and PostgreSQL
          </li>
          <li>
            Implemented a system to ingest data for tens of thousands of ticker
            symbols and hundreds of thousands of financial reports into a
            normalized format, and allowing users to search for companies by
            specifying criteria for 20 financial indicators
          </li>
          <li>
            Designed and developed a real-time group chat system with stock
            ticker auto-completion based on Apollo GraphQL
          </li>
          <li>Instrumented the app for analytics using Segment and Mixpanel</li>
        </ul>
      </>
    ),
    technologies: [
      "React",
      "GraphQL",
      "Node.js",
      "TypeScript",
      "PostgreSQL",
      "Redis",
      "WebSockets",
      "Segment"
    ]
  },
  arxivlab: {
    key: "arxivlab",
    title: "ArXivLab",
    subtitle: "Academic research discovery platform",
    images: [arxivlab1, arxivlab2, arxivlab3, arxivlab4],
    bgColor: "#dee6f0",
    textColor: "#225a98",
    imageShadowColor: "rgba(34, 90, 152, 0.10)",
    content: (
      <>
        <p>
          Backend development, machine learning, server architecture, some
          frontend
        </p>
        <p>
          ArXivLab is an interface to arXiv, an academic e-print service with
          over one million papers. ArXivLab includes research discovery features
          like personalized recommendations and machine learning-based article
          similarity models. It is developed at the Blei Lab at Columbia
          University, where I was a research assistant.
        </p>
        <ul>
          <li>
            Developed and deployed two machine learning models (word embeddings,
            topic modeling) for article similarity over the entire 1.3 million
            article database, with the data pipeline built in Python, frontend
            in React, and API in Node.js
          </li>
          <li>
            Contributed to Custom Views, a feature for researchers to segment
            the arXiv database to tailor their literature search, using React
            and Node.js
          </li>
          <li>
            Managed and optimized the migration of 1.2 billion data points into
            MySQL
          </li>
          <li>
            Developed and optimized an article popularity system using MySQL and
            Node.js
          </li>
          <li>Redesigned the server architecture using Docker</li>
        </ul>
      </>
    ),
    technologies: ["Python", "React", "Node.js", "MySQL", "Docker"]
  },
  kindred: {
    key: "kindred",
    title: "Kindred",
    subtitle: "Referral recruiting software",
    images: [kindred1, kindred2, kindred3, kindred4],
    bgColor: "#eef4f9",
    textColor: "#446e96",
    imageShadowColor: "rgba(34, 90, 152, 0.10)",
    content: (
      <>
        <p>
          Design and development of full product and marketing pages, data
          ingestion and management, payment integration
        </p>
        <p>
          Kindred is a recruiting tech product that helps companies hire from
          their existing employee networks. It allows employees to opt in to
          allow recruiters to search for candidates that existing employees are
          already connected to, automating a process that’s happening manually
          within companies today, or not at all.
        </p>
        <ul>
          <li>
            Designed and developed a single-page application (SPA) frontend,
            along with responsive marketing and pricing pages, using React and
            GraphQL
          </li>
          <li>
            Developed a backend and API with user accounts, permissions,
            employee inviting, and user management using Node.js, TypeScript,
            and GraphQL
          </li>
          <li>
            Optimized PostgreSQL database queries for finding matching
            candidates for a position
          </li>
          <li>
            Implemented a data processing queue to process user-given data
          </li>
          <li>
            Implemented payment integration to allow customers to subscribe to
            the application using Stripe
          </li>
        </ul>
      </>
    ),
    technologies: [
      "React",
      "GraphQL",
      "Node.js",
      "TypeScript",
      "PostgreSQL",
      "Redis",
      "Stripe"
    ]
  }
};
