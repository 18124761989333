import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import styled from "styled-components";
import styledTS from "styled-components-ts";
import Projects from "../components/projects";
import Hero from "../components/hero";
import ContactForm from "../components/contactForm";

interface IPaneDataObject {
  title: string;
  textColor: string;
  borderColor: string;
  content: IPaneDataContentObject[];
}

interface IPaneDataContentObject {
  title: string;
  text: string;
  link?: string;
  linkIsInternal?: boolean;
  showLearnMore?: boolean;
}

const paneData: { [id: string]: IPaneDataObject } = {
  specializations: {
    title: "I specialize in two focus areas.",
    textColor: "#009494",
    borderColor: "#80caca",
    content: [
      {
        title: "Internal tools for streamlining business processes",
        text:
          "Internal tools can have high leverage in improving business outcomes. I work on identifying where internal tools can have the most impact, focusing on ROI based on an objective (e.g. revenue), and designing and building new internal tools.",
        link: "/internal-tools",
        linkIsInternal: true,
        showLearnMore: true
      },
      {
        title: "First versions of B2B/B2C web apps",
        text:
          "Understanding product value-add for the end user and user behavior loops. Designing a beautiful, functional product. End-to-end implementation of entire product including frontend, backend, and UI.",
        link: "/apps",
        linkIsInternal: true,
        showLearnMore: true
      }
    ]
  },
  values: {
    title: "I\u2019m driven by values in my work.",
    textColor: "#6e50e6",
    borderColor: "#b7a8fc",
    content: [
      {
        title: "Focus on value creation",
        text:
          "Software should have positive ROI. I start by defining what the business values and make sure what we build creates that value."
      },
      {
        title: "Clear communication",
        text:
          "Setting the right expectations with a clearly defined spec and schedule, resulting in more peace of mind with the development process."
      },
      {
        title: "Detailed documentation",
        text:
          "Good documentation helps users get the most out of software, assists others working on the project in the future, and protects your investment."
      }
    ]
  },
  experience: {
    title: "My experience spans consulting, engineering, and academia.",
    textColor: "#6f0c3c",
    borderColor: "#b7869e",
    content: [
      {
        title: "Design & engineering",
        text:
          "Core competencies in React, Node.js, JavaScript, Ruby on Rails, Python, PostgreSQL, CSS, and UI design."
      },
      {
        title: "CTO, Onswipe",
        text:
          "Architected platform that handled 40+ million uniques and 100+ million pageviews, serving content for major media companies, based on Node.js."
      },
      {
        title: "Columbia University",
        text:
          "BA, Computer Science, summa cum laude \u2014 Blei Lab, Data Science Institute \u2014 Center for Decision Sciences"
      }
    ]
  },
  contact: {
    title: "Let\u2019s work together.",
    textColor: "#51a3ff",
    borderColor: "#a8d1ff",
    content: [
      {
        title: "Email me",
        text: "Let\u2019s talk about your project.",
        link: "/contact",
        linkIsInternal: true,
        showLearnMore: false
      },
      {
        title: "View appointment calendar",
        text: "Schedule a call directly.",
        link: "https://calendly.com/markbao/consulting-intro-meeting",
        linkIsInternal: false,
        showLearnMore: false
      },
      {
        title: "Forward calendar link",
        text: "Send my calendar to your scheduler.",
        link:
          "mailto:?subject=Please%20schedule%20a%20call%20with%20Mark%20Bao&body=https%3A%2F%2Fcalendly.com%2Fmarkbao%2Fconsulting-intro-meeting",
        linkIsInternal: false,
        showLearnMore: false
      }
    ]
  }
};

const IndexPage = () => (
  <Layout pageName={null} fullWidth={true} indexPage={true}>
    <Hero />
    <div className="container px-6 mx-auto">
      <PaneComponent paneDataObject={paneData.specializations} />
      <PaneComponent paneDataObject={paneData.values} />
      <PaneComponent paneDataObject={paneData.experience} />
      <PaneComponent paneDataObject={paneData.contact} />
    </div>
    <a id="work" />
    <Projects />
    <div className="container px-6 mx-auto pt-4 pb-16">
      <ContactForm formType="message" />
    </div>
  </Layout>
);

export default IndexPage;

interface IPaneComponentProps {
  paneDataObject: IPaneDataObject;
}

const PaneComponent: React.SFC<IPaneComponentProps> = props => {
  const { paneDataObject } = props;
  return (
    <Pane>
      <PaneInner
        textColor={paneDataObject.textColor}
        borderColor={paneDataObject.borderColor}
      >
        <PaneTitle>{paneDataObject.title}</PaneTitle>
        <CommonPaneColumns paneDataContent={paneDataObject.content} />
      </PaneInner>
    </Pane>
  );
};

interface ICommonPaneColumnsProps {
  paneDataContent: IPaneDataContentObject[];
}

const CommonPaneColumns: React.SFC<ICommonPaneColumnsProps> = props => {
  const { paneDataContent } = props;

  return (
    <PaneColumns columns={paneDataContent.length}>
      {paneDataContent.map(data => (
        <PaneColumnSwitch paneDataContentObject={data} key={data.title}>
          <PaneColumnTitle>{data.title}</PaneColumnTitle>
          <PaneColumnText>{data.text}</PaneColumnText>
          {data.showLearnMore && (
            <PaneColumnLearnMore>Learn more &rsaquo;</PaneColumnLearnMore>
          )}
        </PaneColumnSwitch>
      ))}
    </PaneColumns>
  );
};

interface IPaneColumnSelectorProps {
  paneDataContentObject: IPaneDataContentObject;
  children: any;
}

const PaneColumnSwitch: React.SFC<IPaneColumnSelectorProps> = props => {
  const content = props.paneDataContentObject;

  return (
    <>
      {content.link ? (
        <>
          {content.linkIsInternal ? (
            <PaneColumnLink to={content.link}>{props.children}</PaneColumnLink>
          ) : (
            <PaneColumnA href={content.link}>{props.children}</PaneColumnA>
          )}
        </>
      ) : (
        <PaneColumn>{props.children}</PaneColumn>
      )}
    </>
  );
};

const Pane = styled.div.attrs({
  className: "my-20 sm:my-20"
})``;

interface IPaneInnerProps {
  textColor: string;
  borderColor: string;
}

const PaneInner = styledTS<IPaneInnerProps>(styled.div).attrs({})`
  margin: -2rem;
  padding: 2rem;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.textColor};
  
  @media (max-width: 623px) {
    margin: -1rem;
    padding: 1rem;
  }
`;

const PaneTitle = styled.h2.attrs({
  className: "mt-0 mb-8"
})`
  font-size: 30px;
  padding: 0;
  letter-spacing: -0.03em;
`;

const PaneColumn = styled.div``;

const PaneColumnLink = styled(Link)`
  color: inherit;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const PaneColumnA = styled.a`
  display: block;
  color: inherit;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const PaneColumnTitle = styled.h3.attrs({
  className: "mt-0 mb-2"
})``;

const PaneColumnText = styled.p`
  color: #4b4b4b;
  line-height: 1.3em;
`;

const PaneColumnLearnMore = styled.div.attrs({
  className: "mt-2"
})``;

interface IPaneColumnsProps {
  columns: number;
}

const PaneColumns = styledTS<IPaneColumnsProps>(styled.div).attrs({
  className: "flex flex-wrap justify-between"
})`
  ${PaneColumn}, ${PaneColumnLink}, ${PaneColumnA} {
    width: ${props => (props.columns === 2 ? "48" : "31")}%;
    
    @media (max-width: 623px) {
      width: 100%;
      margin-bottom: 2rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
